/** @module common_layout トップページ用のレイアウトスクリプト */
import $ from 'jquery';
// jQueryのプラグインを読み込む
import 'fullpage.js';
// TODO: `app/assets/stylesheets/common_layout/_jquery.fullPage.scss`を廃止し、npm版のCSSに移行する
// ...scss版の方はjquery.fullPageと関係ないスタイルが入ってしまってます・・・
// import 'fullpage.js/dist/jquery.fullpage.css'

(function ($) {
  "use strict";
  $(function () {
    $('#fullpage').fullpage({
      resize: true,
      slidesNavigation: true,
      controlArrows: true,
      loopHorizontal: true,
      autoScrolling: false,
      keyboardScrolling: false,
      scrollingSpeed: 5,
      fitToSection: false, // コンテンツの高さを自動調整
    });
    $.fn.fullpage.setAllowScrolling(false, 'left, right');
  });
})($);

var fade_in_time = 1000,
    auto_set_time = 5000,
    slide_no_1 = '.slide-no-1',
    slide_no_2 = '.slide-no-2',
    intervalTimer,
    currentMainvisualSlide,
    slide_nums = { slide1: slide_no_2, slide2: slide_no_1 };

//共通スライドアクション
function slide_action() {
  currentMainvisualSlide = $('.fp-next, .fp-prev, .fp-slidesNav li').closest('#slides_height').find('.fp-slides > .fp-slidesContainer .active').attr('data-anchor');
  $(slide_nums[currentMainvisualSlide]).css('display', 'none');
  $.fn.fullpage.moveSlideRight();
  $(slide_nums[currentMainvisualSlide]).fadeIn(fade_in_time);
};

// 自動スライド処理関数 クリックしてから5秒後発火する
function startTimer(){
  intervalTimer = setInterval(function(){
    slide_action();
  }, auto_set_time);
};
startTimer();

// クリックイベント
$(function() {
  $('.fp-next, .fp-prev, .fp-slidesNav li').on('click', function() {
    clearInterval(intervalTimer);
    slide_action();
    startTimer();
  });
});
